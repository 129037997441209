import { Close } from '@mui/icons-material'
import { Button, CircularProgress, Grid, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'

export default function CreateCategory() {
    const [isCategoryUpdated, setIsCategoryUpdated] = useState(false)
    const [isCategoryUpdating, setIsCategoryUpdating] = useState(false)

    const [name, setName] = useState('')

    const updateCategory = async () => {

        setIsCategoryUpdating(true)

        const bodyData = new FormData()
        bodyData.set('name', name)

        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/cars/categories`,
            data: bodyData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem("data")).token}`
            }
        })
            .then(function (response) {
                try {
                    if (response.data?.responseCode === '0') {
                        setIsCategoryUpdated(true)
                        setIsCategoryUpdating(false)
                        setName('')
                    }
                } catch {
                    //
                }
            })
            .catch(function (error) {
                //
            })
    }

    return (
        <div>
            <Snackbar
                open={isCategoryUpdated}
                autoHideDuration={6000}
                onClose={() => {
                    setIsCategoryUpdated(false)
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                message="Operation effectuée avec succès !"
                action={<IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        setIsCategoryUpdating(false)
                    }}
                >
                    <Close fontSize="small" />
                </IconButton>}
            />
            <br /><br /><br />
            <Grid container>
                <Grid item xs={6}>
                    <Typography typography={`h4`}>
                        Créer une catégorie
                    </Typography><br /><br />

                    <TextField value={name} label="Nom de la catégorie" variant="outlined" sx={{ width: 300 }} onChange={(e) => {
                        setName(e.target.value)
                    }} />&nbsp;&nbsp;

                    <br /><br />

                    <Button variant='contained' size='large' disabled={isCategoryUpdating} disableElevation onClick={() => {
                        updateCategory()
                    }}>
                        {isCategoryUpdating ? <CircularProgress size={22} /> : <strong>Créer</strong>}
                    </Button>&nbsp;&nbsp;
                </Grid>
            </Grid>
        </div>
    )
}